import BigNumber from 'bignumber.js';

export function calculateNotionalLimit(order) {
  const betSize = new BigNumber(order.stake.baseAmount);
  const leverage = Number(order.leverage);

  return betSize
  .multipliedBy(leverage)
  .decimalPlaces(2, BigNumber.ROUND_HALF_EVEN)
  .toNumber();
}

export function calculateAggregateNotionalLimit(orders) {
  return orders.reduce((total, order) => total + calculateNotionalLimit(order), 0);
}
