<template>
  <ModalMainNew
    modal-width-mode="xl"
    show-close-mobile
    secondary-background-color
    @close="onCloseModal"
  >
    <div class="animate-slide-down-fade-in01s max-w-[375px] mx-auto">
      <PanelTradingOrderForm
        v-if="tradingOrderFormModalData"
        :asset-code="tradingOrderFormModalData"
        @order-added="onCloseModal"
      />
    </div>
  </ModalMainNew>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const uiStore = useUiStore();

const {
  showTradingOrderFormModal,
  tradingOrderFormModalData,
} = storeToRefs(uiStore);

function onCloseModal() {
  showTradingOrderFormModal.value = false;
  tradingOrderFormModalData.value = null;
}
</script>
