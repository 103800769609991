import BigNumber from 'bignumber.js';

export function calculateBustPrice(asset, isLong, leverage) {
  const entryPrice = new BigNumber(asset.price);
  const bustBuffer = new BigNumber(asset.bustBuffer);
  const tradeSign = isLong ? 1 : -1;
  const leverageAdjustment = new BigNumber(1).dividedBy(leverage);
  const closePrice = entryPrice.multipliedBy(new BigNumber(1).minus(tradeSign * leverageAdjustment.toNumber()));
  const triggerPrice = closePrice.multipliedBy(new BigNumber(1).plus(tradeSign * bustBuffer.toNumber())).plus(entryPrice.multipliedBy(bustBuffer).dividedBy(leverage));
  return triggerPrice.decimalPlaces(asset.decimals, isLong ? BigNumber.ROUND_UP : BigNumber.ROUND_DOWN);
}
